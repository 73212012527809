@charset "UTF-8";
/*
 * 既存の汎用CSSを拡張してもらう想定で追加
 * SCSSへの取り込みができたらこのファイルは削除してもらう
 */
/*
 * グリッドレイアウト
 */
.row {
  display: flex;
  flex-wrap: wrap;
}

.row.gutter-xs {
  margin-top: -4px;
  margin-left: -4px;
}

.row.gutter-sm {
  margin-top: -8px;
  margin-left: -8px;
}

.row.gutter-md {
  margin-top: -16px;
  margin-left: -16px;
}

.row > .col-1,
.row > .col-2,
.row > .col-3,
.row > .col-4,
.row > .col-6,
.row > .col-12 {
  flex: 0 0 auto;
  max-width: 100%;
  min-width: 0;
}

.row > .col-1 {
  width: 8.3333333333%;
}

.row > .col-2 {
  width: 16.6666666667%;
}

.row > .col-3 {
  width: 25%;
}

.row > .col-4 {
  width: 33.3333333333%;
}

.row > .col-6 {
  width: 50%;
}

.row > .col-12 {
  width: 100%;
}

.row.gutter-xs > .col-1,
.row.gutter-xs > .col-2,
.row.gutter-xs > .col-3,
.row.gutter-xs > .col-4,
.row.gutter-xs > .col-6,
.row.gutter-xs > .col-12 {
  padding-top: 4px;
  padding-left: 4px;
}

.row.gutter-sm > .col-1,
.row.gutter-sm > .col-2,
.row.gutter-sm > .col-3,
.row.gutter-sm > .col-4,
.row.gutter-sm > .col-6,
.row.gutter-sm > .col-12 {
  padding-top: 8px;
  padding-left: 8px;
}

.row.gutter-md > .col-1,
.row.gutter-md > .col-2,
.row.gutter-md > .col-3,
.row.gutter-md > .col-4,
.row.gutter-md > .col-6,
.row.gutter-md > .col-12 {
  padding-top: 16px;
  padding-left: 16px;
}

/* PC */
@media screen and (min-width: 768px) {
  .row > .col-pc-1 {
    width: 8.3333333333%;
  }

  .row > .col-pc-2 {
    width: 16.6666666667%;
  }

  .row > .col-pc-3 {
    width: 25%;
  }

  .row > .col-pc-4 {
    width: 33.3333333333%;
  }

  .row > .col-pc-6 {
    width: 50%;
  }

  .row > .col-pc-12 {
    width: 100%;
  }
}
/*
 * _btn.scss の拡張を想定
 */
.btn-cmn__ico {
  fill: #fff;
  transition: 0.3s;
}

.btn-cmn:hover svg {
  fill: #11299b;
}

.btn-cmn--light-blue {
  background: #526be2;
  border: 1px solid #526be2;
}

.btn-cmn--light-blue:hover {
  background: #fff;
  color: #526be2;
}

.btn-outline {
  display: inline-block;
  width: 31.25vw;
  padding: 2vw 0;
  background: #fff;
  border: 1px solid #11299b;
  color: #11299b;
  font-size: 4rem;
  transition: 0.3s;
  text-align: center;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .btn-outline {
    width: 100px;
    padding: 3px 0;
    font-size: 1.4rem;
  }
}
.btn-outline:hover {
  background: #11299b;
  color: #fff;
}

.btn-outline--size-free {
  width: auto;
  padding-left: 7vw;
  padding-right: 7vw;
}

/*
 * トップページのCSSを上書き
 */
.top-mv-arrow {
  color: #111;
}

.top-mv-arrow__bar:before {
  background: #111;
}

/*
 * .form-select (QuasarのCSSを排除するまでの応急処置)
 */
.form-select {
  line-height: normal;
}

/*
 * buycar 一覧
 */
.buycar-list {
  display: grid;
  gap: 16px;
}

.buycar-list__img {
  width: 100%;
  aspect-ratio: 4/3;
}

.buycar-list__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buycar-list__inner {
  position: relative;
  padding: 20px;
}

.buycar-list__sec1 {
  border-bottom: 1px solid grey;
  padding-bottom: 6px;
}

.buycar-list__car-name {
  color: var(--base-blue);
  font-size: 1.2em;
  line-height: 1.2;
  height: 3.6em;
  font-weight: bold;
  /* .font-bold */
}

.buycar-list__price {
  text-align: right;
}

.buycar-list__price span {
  font-size: 1.4em;
  color: #cc0000;
  /* .font-red */
  font-weight: bold;
  /* .font-bold */
}

.buycar-list__sec2 {
  padding-top: 10px;
}

.buycar-list__btn-area {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .top .buycar-list {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .buycar .buycar-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}